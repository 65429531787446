<template>
  <div class="about-us-hero" :style="{ 'background-image': 'url(' + require('@/assets/images/pages/about-us.png') + ')'}">
    <div class="overlay"></div>
    <h1 class="about-us-title">About Us/<span class="section-label">{{ title }}</span></h1>
  </div>
</template>
<script>

export default {
  props: {
    title: {
      type: String,
      default: "Leadership",
    },
  },
};
</script>

<style lang="scss">
.about-us-hero {
  background-size: 100% 400px;
  height: 400px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #2e7f73;
  background-blend-mode: luminosity;
}

.about-us-image {
  max-height: 500px;
  width: 100%;
  object-fit: cover;
}

.about-us-title {
  position: absolute;
  color: white;
  font-weight: 900;
  font-size: 4rem;
  left: 2%;
  bottom: 10%;
}

.section-label {
  color: yellow;
  font-size: 3rem;
  font-weight: 800;
  text-transform: capitalize;
}

.overlay {
  background-color: rgba($color: #175dff, $alpha: 0.3);
  width: 100%;
  height: 100%;
}
</style>
